/* eslint-disable */

/**
 * This Source Code Form is subject to the terms of the Mozilla Public
 * License, v. 2.0. If a copy of the MPL was not distributed with this
 * file, you can obtain one at https://mozilla.org/MPL/2.0/.
 *
 * Copyright Oxide Computer Company
 */

import { HttpClient, toQueryString, type FetchParams } from './http-client'

export type { ApiConfig, ApiResult, ErrorBody, ErrorResult } from './http-client'

export type Disk = { id: number; uuid: string; size: number; storageId: string }

export type Disks = { items: Disk[] }

export type DiskRequest = {
  id: number
  size: number
  storageId: string
  storageName: string
}

export type NetworkRequest = { id: number; networkId: string; connected: boolean }

export type CreateVirtualMachineOnHostRequest = {
  name: string
  cpu: number
  memory: number
  disks: DiskRequest[]
  networks: NetworkRequest[]
  hostId: string
  cdrom?: { storageId: string; isoPath: string; connected: boolean }
}

export type AddHostRequest = {
  name: string
  hostName: string
  port: number
  computeClusterId: string
}

export type StorageType = 'NFS' | 'LOCAL'

export type Storage = {
  id: string
  name: string
  mountPoint: string
  storageType: StorageType
  capacityInBytes?: number
  freeInBytes?: number
  hostId: string
}

export type IpAddressType = 'IPV4' | 'IPV6'

export type IpAddress = { ip: string; type: IpAddressType }

export type Network = { id: string; name: string; ipAddresses?: IpAddress[] }

export type PhysicalNetworkType = 'PHYSICAL' | 'BRIDGE'

export type PhysicalNetwork = {
  name: string
  type: PhysicalNetworkType
  ipAddresses?: IpAddress[]
}

export type HostStatus = 'READY' | 'UNINITIALIZED' | 'MAINTENANCE'

export type HostStats = { usedCpu: number; usedMemory: number; usedStorages: number }

export type Host = {
  name: string
  cpu: number
  memory: number
  storage: number
  vms: number
  vmStorages?: Storage[]
  vmNetwork?: Network
  localStorages?: Storage[]
  physicalNetworks?: PhysicalNetwork[]
  status: HostStatus
  hostStats: HostStats
  id: string
}

export type AddHostResponse = { host: Host; jobId: string }

export type AddStorageRequest = {
  type: StorageType
  name: string
  path?: string
  server?: string
  port?: number
}

export type AsyncRequestStatus = 'queued' | 'running' | 'finished' | 'failed' | 'scheduled'

export type ComputeCluster = {
  id: string
  dataCenterId: string
  name: string
  hosts: Host[]
}

export type ConfigVirtualMachineResponse = { vmId?: string; jobId?: string }

export type CreateComputeClusterRequest = { name: string }

export type CreateDataCenterRequest = { name: string }

export type VMStatus =
  | 'CREATING'
  | 'STARTING'
  | 'RUNNING'
  | 'STOPPING'
  | 'STOPPED'
  | 'REBOOTING'
  | 'FAILED'
  | 'DESTROYED'

export type Vnc = { port?: number; host?: string }

export type VirtualMachine = {
  name: string
  status: VMStatus
  id: string
  uuid: string
  cpu: number
  memory: number
  ipAddresses?: IpAddress[]
  vnc?: Vnc
  hostId: string
  vmConfig: string
}

export type CreateVirtualMachineResponse = { vm: VirtualMachine; jobId: string }

export type DataCenter = { id: string; name: string; computeClusters: ComputeCluster[] }

export type DataCenterResponse = { dataCenters: DataCenter[] }

export type File = {
  /** Name of the file or directory (may contain spaces and special characters) */
  name: string
  /** URL-encoded name for use in subsequent API calls */
  encodedName?: string
  /** Type of the item */
  type: 'file' | 'directory'
  /** Size of the file in bytes (null for directories) */
  size: number
  /** Last modification date and time */
  modifiedAt: Date
}

export type Job = {
  id: string
  objectId: string
  status: AsyncRequestStatus
  progress: number
  type: string
}

export type PaginationInfo = {
  /** Total number of items */
  total: number
  /** Current page number */
  page: number
  /** Number of items per page */
  limit: number
}

export type PaginatedResponse = { pagination: PaginationInfo }

export type ListFilesResponse = {
  /** Current path being listed (URL decoded) */
  path: string
  items: File[]
}

export type LoginRequest = {
  /** The user's username */
  username: string
  /** The user's password */
  password: string
}

export type LoginResponse = { sessionId?: string; errorMessage?: string }

export type PaginatedHostResponse = { items: Host[]; nextPage?: string }

export type PaginatedJobResponse = { $ref: '#/components/schemas/PaginatedResponse' } & {
  type: 'object'
  properties: { data: { type: 'array'; items: { $ref: '#/components/schemas/Job' } } }
  required: ['data']
}

export type PaginatedStorageResponse = { items: Storage[]; nextPage?: string }

export type PaginatedVMResponse = { items: VirtualMachine[]; nextPage?: string }

export type PowerOffVirtualMachineResponse = { vmId: string; jobId: string }

export type PowerOnVirtualMachineResponse = { vmId: string; jobId: string }

export type RefreshHostResponse = { jobId: string }

export type ResetVirtualMachineResponse = { vmId: string; jobId: string }

export interface GetHostsQueryParams {
  limit?: number
  page?: number
}

export interface GetHostPathParams {
  hostId: string
}

export interface GetVmsOnHostPathParams {
  hostId: string
}

export interface GetVmsOnHostQueryParams {
  limit?: number
  page?: number
}

export interface GetStoragesOnHostPathParams {
  hostId: string
}

export interface GetStoragesOnHostQueryParams {
  limit?: number
  page?: number
}

export interface AddStoragePathParams {
  hostId: string
}

export interface RefreshHostPathParams {
  hostId: string
}

export interface GetVmsQueryParams {
  limit?: number
  page?: number
}

export interface GetVmPathParams {
  vmId: string
}

export interface GetVmDisksPathParams {
  vmId: string
}

export interface PowerOffVmPathParams {
  vmId: string
}

export interface PowerOnVmPathParams {
  vmId: string
}

export interface ConfigVmPathParams {
  vmId: string
}

export interface ResetVmPathParams {
  vmId: string
}

export interface GetStoragesQueryParams {
  limit?: number
  page?: number
}

export interface UploadFilePathParams {
  storageId: string
}

export interface ListFilesPathParams {
  storageId: string
}

export interface ListFilesQueryParams {
  path?: string
}

export interface CreateDirectoryPathParams {
  storageId: string
}

export interface QueryJobPathParams {
  jobId: string
}

export interface ListJobsForObjectPathParams {
  objectId: string
}

export interface CreateComputeClusterPathParams {
  dcId: string
}

type EmptyObj = Record<string, never>
export class Api extends HttpClient {
  methods = {
    /**
     * Ping
     */
    ping: (_: EmptyObj, params: FetchParams = {}) => {
      return this.request<void>({
        path: `/v1/ping`,
        method: 'GET',
        ...params,
      })
    },
    /**
     * Login
     */
    login: ({ body }: { body: LoginRequest }, params: FetchParams = {}) => {
      return this.request<LoginResponse>({
        path: `/v1/login`,
        method: 'POST',
        body,
        ...params,
      })
    },
    /**
     * Get hosts.
     */
    getHosts: (
      { query = {} }: { query?: GetHostsQueryParams },
      params: FetchParams = {}
    ) => {
      return this.request<PaginatedHostResponse>({
        path: `/v1/host`,
        method: 'GET',
        query,
        ...params,
      })
    },
    /**
     * Add Host.
     */
    addHost: ({ body }: { body: AddHostRequest }, params: FetchParams = {}) => {
      return this.request<AddHostResponse>({
        path: `/v1/host`,
        method: 'POST',
        body,
        ...params,
      })
    },
    /**
     * Get host.
     */
    getHost: ({ path }: { path: GetHostPathParams }, params: FetchParams = {}) => {
      return this.request<Host>({
        path: `/v1/host/${path.hostId}`,
        method: 'GET',
        ...params,
      })
    },
    /**
     * Get vms for a host.
     */
    getVmsOnHost: (
      {
        path,
        query = {},
      }: { path: GetVmsOnHostPathParams; query?: GetVmsOnHostQueryParams },
      params: FetchParams = {}
    ) => {
      return this.request<PaginatedVMResponse>({
        path: `/v1/host/${path.hostId}/vm`,
        method: 'GET',
        query,
        ...params,
      })
    },
    /**
     * Get vms for a host.
     */
    getStoragesOnHost: (
      {
        path,
        query = {},
      }: { path: GetStoragesOnHostPathParams; query?: GetStoragesOnHostQueryParams },
      params: FetchParams = {}
    ) => {
      return this.request<PaginatedStorageResponse>({
        path: `/v1/host/${path.hostId}/storage`,
        method: 'GET',
        query,
        ...params,
      })
    },
    /**
     * Add a storage to a host
     */
    addStorage: (
      { path, body }: { path: AddStoragePathParams; body: AddStorageRequest },
      params: FetchParams = {}
    ) => {
      return this.request<Storage>({
        path: `/v1/host/${path.hostId}/storage`,
        method: 'POST',
        body,
        ...params,
      })
    },
    /**
     * Refresh host.
     */
    refreshHost: ({ path }: { path: RefreshHostPathParams }, params: FetchParams = {}) => {
      return this.request<RefreshHostResponse>({
        path: `/v1/host/${path.hostId}/refresh`,
        method: 'POST',
        ...params,
      })
    },
    /**
     * Get a list of virtual machines.
     */
    GetVms: ({ query = {} }: { query?: GetVmsQueryParams }, params: FetchParams = {}) => {
      return this.request<PaginatedVMResponse>({
        path: `/v1/vm`,
        method: 'GET',
        query,
        ...params,
      })
    },
    /**
     * Create a virtual machine.
     */
    createVm: (
      { body }: { body: CreateVirtualMachineOnHostRequest },
      params: FetchParams = {}
    ) => {
      return this.request<CreateVirtualMachineResponse>({
        path: `/v1/vm`,
        method: 'POST',
        body,
        ...params,
      })
    },
    /**
     * Get vm by vm id
     */
    getVm: ({ path }: { path: GetVmPathParams }, params: FetchParams = {}) => {
      return this.request<VirtualMachine>({
        path: `/v1/vm/${path.vmId}`,
        method: 'GET',
        ...params,
      })
    },
    /**
     * Get disks for a vm
     */
    getVmDisks: ({ path }: { path: GetVmDisksPathParams }, params: FetchParams = {}) => {
      return this.request<Disks>({
        path: `/v1/vm/${path.vmId}/disk`,
        method: 'GET',
        ...params,
      })
    },
    /**
     * Power off a virtual machine.
     */
    powerOffVm: ({ path }: { path: PowerOffVmPathParams }, params: FetchParams = {}) => {
      return this.request<PowerOffVirtualMachineResponse>({
        path: `/v1/vm/${path.vmId}/poweroff`,
        method: 'POST',
        ...params,
      })
    },
    /**
     * Power on a virtual machine.
     */
    powerOnVm: ({ path }: { path: PowerOnVmPathParams }, params: FetchParams = {}) => {
      return this.request<PowerOnVirtualMachineResponse>({
        path: `/v1/vm/${path.vmId}/poweron`,
        method: 'POST',
        ...params,
      })
    },
    /**
     * Config a virtual machine.
     */
    configVm: ({ path }: { path: ConfigVmPathParams }, params: FetchParams = {}) => {
      return this.request<ConfigVirtualMachineResponse>({
        path: `/v1/vm/${path.vmId}/onfig`,
        method: 'POST',
        ...params,
      })
    },
    /**
     * Reset a virtual machine.
     */
    resetVm: ({ path }: { path: ResetVmPathParams }, params: FetchParams = {}) => {
      return this.request<ResetVirtualMachineResponse>({
        path: `/v1/vm/${path.vmId}/reset`,
        method: 'POST',
        ...params,
      })
    },
    /**
     * Get a list of storages.
     */
    GetStorages: (
      { query = {} }: { query?: GetStoragesQueryParams },
      params: FetchParams = {}
    ) => {
      return this.request<PaginatedStorageResponse>({
        path: `/v1/storage`,
        method: 'GET',
        query,
        ...params,
      })
    },
    /**
     * Upload a file
     */
    uploadFile: ({ path }: { path: UploadFilePathParams }, params: FetchParams = {}) => {
      return this.request<void>({
        path: `/v1/storage/${path.storageId}/upload`,
        method: 'POST',
        ...params,
      })
    },
    /**
     * List files and directories
     */
    listFiles: (
      { path, query = {} }: { path: ListFilesPathParams; query?: ListFilesQueryParams },
      params: FetchParams = {}
    ) => {
      return this.request<ListFilesResponse>({
        path: `/v1/storage/${path.storageId}/files`,
        method: 'GET',
        query,
        ...params,
      })
    },
    /**
     * Create directory
     */
    createDirectory: (
      { path }: { path: CreateDirectoryPathParams },
      params: FetchParams = {}
    ) => {
      return this.request<void>({
        path: `/v1/storage/${path.storageId}/files`,
        method: 'POST',
        ...params,
      })
    },
    /**
     * Get job.
     */
    queryJob: ({ path }: { path: QueryJobPathParams }, params: FetchParams = {}) => {
      return this.request<Job>({
        path: `/v1/job/${path.jobId}`,
        method: 'POST',
        ...params,
      })
    },
    /**
     * List jobs for an object.
     */
    listJobsForObject: (
      { path }: { path: ListJobsForObjectPathParams },
      params: FetchParams = {}
    ) => {
      return this.request<PaginatedJobResponse>({
        path: `/v1/job/object/${path.objectId}`,
        method: 'POST',
        ...params,
      })
    },
    /**
     * Get hierarchy.
     */
    getAllDataCenters: (_: EmptyObj, params: FetchParams = {}) => {
      return this.request<DataCenterResponse>({
        path: `/v1/datacenter`,
        method: 'GET',
        ...params,
      })
    },
    /**
     * Create DataCenter.
     */
    createDataCenter: (
      { body }: { body: CreateDataCenterRequest },
      params: FetchParams = {}
    ) => {
      return this.request<DataCenter>({
        path: `/v1/datacenter`,
        method: 'POST',
        body,
        ...params,
      })
    },
    /**
     * Create Compute Cluster.
     */
    createComputeCluster: (
      {
        path,
        body,
      }: { path: CreateComputeClusterPathParams; body: CreateComputeClusterRequest },
      params: FetchParams = {}
    ) => {
      return this.request<ComputeCluster>({
        path: `/v1/datacenter/${path.dcId}/compute_cluster`,
        method: 'POST',
        body,
        ...params,
      })
    },
  }
  ws = {}
}

export default Api
