import { useMemo } from 'react'
import { useController, useForm } from 'react-hook-form'

import { useApiMutation, useApiQueryClient, usePrefetchedApiQuery } from '~/api'
import type { StorageType } from '~/api/__generated__/Api.ts'
import { ComboboxField } from '~/components/form/fields/ComboboxField.tsx'
import { useHostParams } from '~/hooks/use-params.ts'
import { addToast } from '~/stores/toast.ts'
import { Modal } from '~/ui/lib/Modal.tsx'

type Values = { storageType: StorageType | null; path: string | null }
const defaultValues: Values = { storageType: 'LOCAL', path: null }
const storageTypes: StorageType[] = ['NFS', 'LOCAL']
const storageTypeItems = storageTypes.map((t) => ({ label: t.toString(), value: t }))
export const AddStorageModal = ({ onDismiss }: { onDismiss: () => void }) => {
  const { hostId } = useHostParams()
  const { data: host } = usePrefetchedApiQuery('getHost', { path: { hostId } })
  const { control, handleSubmit, resetField } = useForm({ defaultValues })
  const {
    field: { value: selectedStoragetype },
  } = useController({ control, name: 'storageType' })
  const queryClient = useApiQueryClient()

  const addStorage = useApiMutation('addStorage', {
    onSuccess(data) {
      addToast({ content: `${data.name} has been added` })
      queryClient.invalidateQueries('getStoragesOnHost')
    },
    onError: (err) => {
      addToast({ title: 'Error', content: err.message, variant: 'error' })
    },
    onSettled: onDismiss,
  })
  //
  // const projects = useApiQuery('projectList', {})
  const localStorages = useMemo(
    () => (host.localStorages || []).map((s) => ({ value: s.name, label: s.name })),
    [host.localStorages]
  )

  const onSubmit = ({ path, storageType }: Values) => {
    if (!path || !storageType) return
    addStorage.mutate({
      path: { hostId },
      body: { type: storageType, path: path, name: path },
    })
  }

  return (
    <Modal isOpen onDismiss={onDismiss} title="Add a storage">
      <Modal.Body>
        <Modal.Section>
          <form autoComplete="off" onSubmit={handleSubmit(onSubmit)} className="space-y-4">
            <ComboboxField
              placeholder="Select a storage type"
              name="storageType"
              label="Storage type"
              items={storageTypeItems}
              onChange={() => {
                resetField('path') // reset image field when the project changes
              }}
              required
              control={control}
            />
            {selectedStoragetype === 'LOCAL' && (
              <ComboboxField
                placeholder="Select a local storage"
                name="path"
                label="Path"
                items={localStorages}
                required
                control={control}
              />
            )}
          </form>
        </Modal.Section>
      </Modal.Body>
      <Modal.Footer
        onDismiss={onDismiss}
        onAction={handleSubmit(onSubmit)}
        actionText="Add"
      />
    </Modal>
  )
}
