/*
 * This Source Code Form is subject to the terms of the Mozilla Public
 * License, v. 2.0. If a copy of the MPL was not distributed with this
 * file, you can obtain one at https://mozilla.org/MPL/2.0/.
 *
 * Copyright Oxide Computer Company
 */
import { createRoutesFromElements, Navigate, Route } from 'react-router-dom'

import { InstancePage } from '~/pages/project/instances/instance/InstancePage.tsx'
import { StorageTab } from '~/pages/project/instances/instance/tabs/StorageTab.tsx'
import { InventoryPage } from '~/pages/system/inventory/InventoryPage.tsx'
import { HostPage } from '~/pages/system/inventory/sled/HostPage.tsx'
import { pb } from '~/util/path-builder.ts'

import { RouterDataErrorBoundary } from './components/ErrorBoundary'
import { NotFound } from './components/ErrorPage'
import { CreateInstanceForm } from './forms/instance-create'
import type { CrumbFunc } from './hooks/use-title'
import { AdminLayout } from './layouts/AdminLayout.tsx'
import { AuthenticatedLayout } from './layouts/AuthenticatedLayout'
import { LoginLayout } from './layouts/LoginLayout'
import { RootLayout } from './layouts/RootLayout'
import { LoginPage } from './pages/LoginPage'
import { LoginPageSaml } from './pages/LoginPageSaml'
import { InstancesPage } from './pages/project/instances/InstancesPage'

const projectCrumb: CrumbFunc = (m) => m.params.project!

export const routes = createRoutesFromElements(
  <Route element={<RootLayout />}>
    <Route path="*" element={<NotFound />} />
    <Route element={<LoginLayout />}>
      <Route path="login/:silo/local" element={<LoginPage />} />
      <Route path="login/:silo/saml/:provider" element={<LoginPageSaml />} />
    </Route>

    {/* This wraps all routes that are supposed to be authenticated */}
    <Route
      element={<AuthenticatedLayout />}
      errorElement={<RouterDataErrorBoundary />}
      // very important. see `currentUserLoader` and `useCurrentUser`
      shouldRevalidate={() => true}
    >
      <Route index element={<Navigate to={pb.instances()} replace />} />
      <Route path="/" element={<AdminLayout />} handle={{ crumb: projectCrumb }}>
        <Route
          path={pb.instancesNew()}
          element={<CreateInstanceForm />}
          loader={CreateInstanceForm.loader}
          handle={{ crumb: 'New instance' }}
        />
        <Route path={pb.instances()} handle={{ crumb: 'Instances' }}>
          <Route index element={<InstancesPage />} loader={InstancesPage.loader} />
          <Route path=":vmId">
            <Route index element={<Navigate to="storage" replace />} />
            <Route element={<InstancePage />} loader={InstancePage.loader}>
              <Route
                path="storage"
                element={<StorageTab />}
                loader={StorageTab.loader}
                handle={{ crumb: 'Storage' }}
              />
            </Route>
          </Route>
        </Route>
        <Route path={pb.hosts()} handle={{ crumb: 'Inventory' }}>
          <Route index element={<InventoryPage />} loader={InventoryPage.loader} />
          <Route path=":hostId">
            <Route index element={<HostPage />} loader={HostPage.loader} />
            <Route
              path="vm-new"
              element={<CreateInstanceForm />}
              loader={CreateInstanceForm.loader}
              handle={{ crumb: 'New instance' }}
            />
          </Route>
        </Route>
      </Route>
    </Route>
  </Route>
)
